import React from "react"
import PropTypes from "prop-types"
import BuildingSize from "assets/images/building-size.svg"
import LandSize from "assets/images/land-size.svg"
import ProjectType from "assets/images/project-type.svg"
import PropertyType from "assets/images/property-type.svg"
import H from "components/atoms/H"
import {
  headingStyles,
  SummaryHeadingWrapper,
  StyledList,
  ListItem,
  SingleItem,
  SummaryWrapper,
} from "./SummarySection.styles"

const icons = {
  PROPERTY_TYPE: <PropertyType />,
  PROJECT_TYPE: <ProjectType />,
  LAND_SIZE: <LandSize />,
  BUILDING_SIZE: <BuildingSize />,
}

const SummarySection = ({ data }) => {
  return (
    <SummaryWrapper>
      <SummaryHeadingWrapper>
        {icons[data.icon]}
        <H as="h4" extraStyles={headingStyles}>
          {data.title}
        </H>
      </SummaryHeadingWrapper>
      {data.content.length > 1 ? (
        <StyledList>
          {data.content.map((item) => (
            <ListItem key={item.type}>{item.type}</ListItem>
          ))}
        </StyledList>
      ) : (
        <SingleItem>{data.content[0]}</SingleItem>
      )}
    </SummaryWrapper>
  )
}

export default SummarySection

SummarySection.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string })),
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }).isRequired,
}
