import styled from "styled-components"

const AnimationContainer = styled.div`
  height: 100%;
`
const StyledAnimation = styled.div`
  height: 100%;

  svg {
    max-height: 31rem;
  }
`

export { AnimationContainer, StyledAnimation }
