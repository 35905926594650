import React, { Fragment } from "react"
import PropTypes from "prop-types"
import P from "components/atoms/P"
import H from "components/atoms/H"
import {
  headerStyles,
  paragraphStyle,
  StyledImage,
  LocationItemWrapper,
} from "./LocationItem.styles"

const LocationItem = ({ data }) => {
  const renderAddressLines = (arr) =>
    arr.map(
      (addressLine) =>
        addressLine && (
          <Fragment key={addressLine}>
            <P extraStyles={paragraphStyle}>{addressLine}</P>
          </Fragment>
        )
    )

  return (
    <LocationItemWrapper>
      <StyledImage src={data.image.image} alt={data.image.title} />
      <div>
        <H as="h4" extraStyles={headerStyles}>
          {data.name}
        </H>
        {renderAddressLines([
          data.addressLine1,
          data.addressLine2,
          data.addressLine3,
        ])}
      </div>
    </LocationItemWrapper>
  )
}

export default LocationItem

LocationItem.propTypes = {
  data: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.string,
    }),
  }).isRequired,
}
