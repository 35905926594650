const getDimensions = (element) => {
  const { height } = element.getBoundingClientRect()
  const { offsetTop } = element
  const offsetBottom = offsetTop + height

  return {
    height,
    offsetTop,
    offsetBottom,
  }
}

export default getDimensions
