import styled, { css } from "styled-components"

const headerStyles = css`
  font-size: 2rem;
  margin-bottom: 1.6rem;
  line-height: 2;
`

const descriptionStyles = css`
  font-size: ${({ theme }) => theme.font.size.md};
`

const stageHeader = css`
  font-size: ${({ theme }) => theme.font.size.l};
  font-family: ${({ theme }) => theme.font.family.body};
  margin-bottom: 0.8rem;
`
const stageDescription = css`
  font-size: ${({ theme }) => theme.font.size.l};
`

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.mq.medium} {
    align-items: center;
    flex-direction: row;
  }
`
const TextContent = styled.div`
  margin-right: 3.2rem;
  margin-top: 4rem;

  align-self: flex-start;

  ${({ theme }) => theme.mq.medium} {
    margin-top: 0rem;
    max-width: 50%;
  }
`
const StepsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3.2rem;

  margin-top: 3.2rem;

  ${({ theme }) => theme.mq.medium} {
    grid-template-columns: 1fr 1fr;
    margin-top: 6.4rem;
  }
`

const StageWrapper = styled.div`
  padding-top: 4rem;

  ${({ theme }) => theme.mq.medium} {
    padding-top: 6rem;
  }
`

export {
  headerStyles,
  descriptionStyles,
  stageHeader,
  stageDescription,
  HeadingWrapper,
  StageWrapper,
  TextContent,
  StepsWrapper,
}
