import styled, { css } from "styled-components"

const SectionWrapper = styled.section`
  background-color: ${({ theme }) => theme.color.main01};

  ${({ theme }) => theme.mq.large} {
    background-color: ${({ theme }) => theme.color.white};
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 1400 1134' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-1.00087 0.864994L517.186 0.864746L699.999 99.9996L1402 100L1401 1134H-1.00098L-1.00087 0.864994Z' fill='%23021F4B'/%3E%3Cpath d='M485.499 0H369.999L466.249 52H581.749L485.499 0Z' fill='%2323E7E5'/%3E%3Cpath d='M401.999 0H517.499L709.999 104H594.499L401.999 0Z' fill='%23083F91'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: cover;
  }
`

const ContentWrapper = styled.div`
  padding: 8rem 1.6rem 7rem;

  ${({ theme }) => theme.mq.large} {
    padding: 18rem 1.6rem 7rem;
    max-width: ${({ theme }) => theme.gridWidth};
    margin: 0 auto;
  }
`

const TextWrapper = styled.div`
  max-width: 77rem;
  margin-bottom: 3.2rem;

  ${({ theme }) => theme.mq.medium} {
    margin-bottom: 5.6rem;
  }
`

const headerStyles = css`
  color: ${({ theme }) => theme.color.white};
`

const subHeaderStyles = css`
  color: ${({ theme }) => theme.color.secondary03};
`

export {
  ContentWrapper,
  TextWrapper,
  headerStyles,
  subHeaderStyles,
  SectionWrapper,
}
