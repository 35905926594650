import React from "react"
import PropTypes, { node } from "prop-types"
import { StyledHeading } from "./SectionSubtitle.styles"

const SectionSubtitle = ({ children, extrastyles, ...rest }) => {
  return (
    <StyledHeading as="h3" extrastyles={extrastyles} {...rest}>
      {children}
    </StyledHeading>
  )
}

SectionSubtitle.propTypes = {
  children: PropTypes.oneOfType([String, node]).isRequired,
  extrastyles: PropTypes.any,
}

SectionSubtitle.defaultProps = {
  extrastyles: "",
}

export default SectionSubtitle
