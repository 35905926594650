import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import SummarySection from "./SummarySection"
import { SummaryWrapper } from "./WhatWeDoSummary.styles"

const WhatWeDoSummary = ({ summaryData }) => {
  const { formatMessage } = useIntl()

  const items = [
    {
      title: formatMessage({ id: "pages.about.whatWeDo.propertyType" }),
      content: summaryData.propertyType,
      icon: "PROPERTY_TYPE",
    },
    {
      title: formatMessage({ id: "pages.about.whatWeDo.projectType" }),
      content: summaryData.projectType,
      icon: "PROJECT_TYPE",
    },
    {
      title: formatMessage({ id: "pages.about.whatWeDo.buildingSize" }),
      content: [summaryData.totalBuildingSize],
      icon: "BUILDING_SIZE",
    },
    {
      title: formatMessage({ id: "pages.about.whatWeDo.landSize" }),
      content: [summaryData.totalLandSize],
      icon: "LAND_SIZE",
    },
  ]

  return (
    <SummaryWrapper>
      {items.map((item) => (
        <SummarySection data={item} key={item.title} />
      ))}
    </SummaryWrapper>
  )
}

export default WhatWeDoSummary

WhatWeDoSummary.propTypes = {
  summaryData: PropTypes.shape({
    totalBuildingSize: PropTypes.string,
    totalLandSize: PropTypes.string,
    projectType: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string })),
    propertyType: PropTypes.arrayOf(
      PropTypes.shape({ type: PropTypes.string })
    ),
  }).isRequired,
}
