import React from "react"
import PropTypes from "prop-types"
import H from "components/atoms/H"
import SectionSubtitle from "components/atoms/SectionSubtitle"
import {
  SectionWrapper,
  ContentWrapper,
  TextWrapper,
  headerStyles,
  subHeaderStyles,
} from "./SeeOurImpact.styles"
import ImpactCaseStudy from "./ImpactCaseStudy"

const SeeOurImpact = ({ sectionData }) => {
  return (
    <SectionWrapper id="seeOurImpact">
      <ContentWrapper>
        <TextWrapper>
          <SectionSubtitle extrastyles={subHeaderStyles}>
            {sectionData.accentMainHeader}
          </SectionSubtitle>
          <H extraStyles={headerStyles} as="h2">
            {sectionData.descriptiveSubheader}
          </H>
        </TextWrapper>
        <ImpactCaseStudy caseStudies={sectionData.impactCaseStudy} />
      </ContentWrapper>
    </SectionWrapper>
  )
}

export default SeeOurImpact

SeeOurImpact.propTypes = {
  sectionData: PropTypes.shape({
    accentMainHeader: PropTypes.string,
    descriptiveSubheader: PropTypes.string,
    impactCaseStudy: PropTypes.arrayOf(
      PropTypes.shape({
        afterImage: PropTypes.string,
        beforeImage: PropTypes.string,
        building: PropTypes.string,
        description: PropTypes.string,
        land: PropTypes.string,
        listPosition: PropTypes.number,
        portfolioLink: PropTypes.string,
        projectTag: PropTypes.string,
        propertyTag: PropTypes.string,
        tabTitle: PropTypes.string,
      })
    ),
  }).isRequired,
}
