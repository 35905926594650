import styled from "styled-components"

const ProcessStagesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.medium} {
    flex-direction: row;
  }
`
const StagesWrapper = styled.div`
  padding: 0rem 1.6rem 0 1.6rem;
  scroll-behavior: smooth;

  ${({ theme }) => theme.mq.medium} {
    margin-left: 6.8rem;
  }
`

export { ProcessStagesWrapper, StagesWrapper }
