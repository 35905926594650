import styled, { css } from "styled-components"

const headingStyles = css`
  margin-left: 1.6rem;
`

const SummaryHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledList = styled.ul`
  margin: 0 0 0 8rem;
  padding: 0 0 0 1.6rem;
`

const textStyles = css`
  font-size: ${({ theme }) => theme.font.size.l};
  color: ${({ theme }) => theme.color.secondary01};
`

const ListItem = styled.li`
  ${textStyles}
  margin-top: 1.2rem;

  &:first-of-type {
    margin-top: 0;
  }
`

const SingleItem = styled.span`
  ${textStyles}
  margin-left: 8rem;
`

const SummaryWrapper = styled.div`
  margin-bottom: 3.5rem;
  margin-right: 3.5rem;
`

export {
  headingStyles,
  SummaryHeadingWrapper,
  StyledList,
  ListItem,
  SingleItem,
  SummaryWrapper,
}
