import styled, { css } from "styled-components"

const SummaryWrapper = styled.div`
  display: grid;
  gap: 2.4rem;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.mq.medium} {
    grid-template-columns: 1fr 1fr;
    margin: 3.2rem 0 3.2rem 0;
  }

  ${({ theme }) => theme.mq.large} {
    grid-template-columns: 1fr 1fr;
  }

  ${({ theme }) => theme.mq.extraLarge} {
    margin: 5.6rem 0 5.6rem 0;
  }
`

const headerStyles = css`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  color: ${({ theme }) => theme.color.secondary03};
  white-space: nowrap;
`
const paragraphStyles = css`
  font-size: ${({ theme }) => theme.font.size.s};
  color: ${({ theme }) => theme.color.white};

  margin-top: 0.4rem;

  ${({ theme }) => theme.mq.medium} {
    max-width: 10rem;
    white-space: nowrap;
    overflow: hidden;
  }
`

const ContentWrapper = styled.div`
  display: flex;
`

const IconWrapper = styled.div`
  width: 5.6rem;
  height: 5.6rem;

  svg {
    width: 100%;
    height: 100%;
  }
`

const TextWrapper = styled.div`
  margin-left: 2.4rem;
`

export {
  SummaryWrapper,
  headerStyles,
  paragraphStyles,
  ContentWrapper,
  IconWrapper,
  TextWrapper,
}
