import React from "react"
import PropTypes from "prop-types"

import P from "components/atoms/P"
import getMediaUrl from "utils/getMediaUrl"
import { useIntl } from "gatsby-plugin-intl"
import CompareImage from "components/atoms/CompareImage"
import theme from "styles/theme"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import useWindowSize from "utils/useWindowSize"
import CaseStudySummary from "./CaseStudySummary"
import {
  paragraphStyles,
  ImageCaption,
  ContentWrapper,
  ImageBlock,
  TextContent,
  ImageWrapper,
  StyledLink,
} from "./CaseStudyContent.styles"
import MobileCompareImage from "./MobileCompareImage"

const CaseStudyContent = ({ data }) => {
  const size = useWindowSize()
  const { formatMessage } = useIntl()

  const renderPortfolioLink = () => (
    <ImageCaption>
      {formatMessage({ id: "pages.about.seeOurImpact.imageCaption" })}{" "}
      <StyledLink
        to={data.portfolioLink}
        onClick={() => {
          trackCustomEventTrigger({
            category: trackingCategoriesHelper.ABOUT,
            action: "Click",
            label: `${trackingCategoriesHelper.ABOUT} (portfolio link)`,
          })
        }}
      >
        {formatMessage({ id: "pages.about.seeOurImpact.imageCaptionLink" })}
      </StyledLink>
    </ImageCaption>
  )

  return (
    <ContentWrapper>
      <ImageBlock>
        <ImageWrapper>
          {size.width < theme.breakpoints.medium ? (
            <MobileCompareImage
              beforeImage={getMediaUrl(data.beforeImage)}
              afterImage={getMediaUrl(data.afterImage)}
            />
          ) : (
            <CompareImage
              leftImage={getMediaUrl(data.beforeImage)}
              rightImage={getMediaUrl(data.afterImage)}
              leftImageLabel={formatMessage({
                id: "pages.about.seeOurImpact.vision.before",
              })}
              rightImageLabel={formatMessage({
                id: "pages.about.seeOurImpact.vision.after",
              })}
              colors={{
                afterLabelTextColor: theme.color.white,
                beforeLabelBackgroundColor: theme.color.white,
                beforeLabelTextColor: theme.color.secondary01,
                sliderAfterLabelBackgroundColor: theme.color.main03,
              }}
            />
          )}
        </ImageWrapper>
        {size.width >= theme.breakpoints.medium && renderPortfolioLink()}
      </ImageBlock>
      <TextContent>
        {size.width < theme.breakpoints.medium && renderPortfolioLink()}
        <P extraStyles={paragraphStyles}>{data.description}</P>
        <CaseStudySummary
          data={{
            projectTag: data.projectTag,
            propertyTag: data.propertyTag,
            land: data.land,
            building: data.building,
          }}
        />
      </TextContent>
    </ContentWrapper>
  )
}

export default CaseStudyContent

CaseStudyContent.propTypes = {
  data: PropTypes.shape({
    afterImage: PropTypes.string,
    beforeImage: PropTypes.string,
    building: PropTypes.string,
    description: PropTypes.string,
    land: PropTypes.string,
    listPosition: PropTypes.number,
    portfolioLink: PropTypes.string,
    projectTag: PropTypes.string,
    propertyTag: PropTypes.string,
    tabTitle: PropTypes.string,
  }).isRequired,
}
