import styled, { css } from "styled-components"
import Button from "components/atoms/Button"

const ListWrapper = styled.div`
  display: flex;

  margin-bottom: 3.2rem;

  ${({ theme }) => theme.mq.large} {
    margin-bottom: 5.5rem;
  }
`

const ListNavigation = styled.div`
  display: none;

  ${({ theme }) => theme.mq.large} {
    display: flex;
    height: 5.6rem;
  }
`

const StyledButton = styled(Button)`
  border: none;
  padding: 0;

  width: 5.6rem;
  height: 5.6rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  svg {
    fill: ${({ theme }) => theme.color.white};

    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.secondary02};
  }
`

const ButtonsDivider = styled.div`
  background-color: ${({ theme }) => theme.color.secondary02};

  width: 1px;
  height: 100%;
`

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 1rem;
  list-style: none;
  overflow-x: scroll;
  scroll-behavior: smooth;
  display: flex;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    /* stylelint-disable */
    -webkit-appearance: none;
    /* stylelint-enable */
    display: none;
    width: 0;
    height: 0;

    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  :-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::after {
    display: block;
    content: "";
    height: 100%;
    width: 1rem;

    flex-shrink: 0;
  }
`

const ListContent = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  overflow: hidden;
`

const gradientDefaultStyles = css`
  pointer-events: none;
  height: 100%;

  position: absolute;
  top: 0;

  display: none;

  ${({ theme }) => theme.mq.large} {
    display: block;
  }
`

const GradientBackground = styled.div`
  ${gradientDefaultStyles}

  width: 5rem;
  right: -0.5rem;

  background: linear-gradient(270deg, #021f4b 0%, rgba(2, 31, 75, 0) 100%);
`

const GradientBackgroundLeft = styled.div`
  ${gradientDefaultStyles}

  width: 2rem;
  left: -0.2rem;
  background: linear-gradient(90deg, #021f4b 0%, rgba(2, 31, 75, 0) 100%);
`

const ListItem = styled.li`
  color: ${({ theme }) => theme.color.secondary03};
  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  white-space: nowrap;
  margin-right: 2rem;
  padding: 1.6rem 0.8rem;

  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.color.white};
      border-bottom: 3px solid ${({ theme }) => theme.color.main03};
    `}

  &:hover {
    transition: color 0.5s;
    color: ${({ theme }) => theme.color.white};
  }
`

export {
  ListWrapper,
  ListNavigation,
  ListContent,
  StyledButton,
  ButtonsDivider,
  StyledList,
  GradientBackground,
  ListItem,
  GradientBackgroundLeft,
}
