import React from "react"
import PropTypes from "prop-types"
import getDimensions from "utils/getDimensions"
import { useIntl } from "gatsby-plugin-intl"
import ChevronRight from "assets/icons/chevron-right.svg"
import { StickyMenu, MenuItem } from "./DesktopMenu.styles"

const scrollTo = (element) => {
  const { offsetTop } = getDimensions(element)

  window.scrollTo({
    top: offsetTop - 59,
    behavior: "smooth",
  })
}

const DesktopMenu = ({ stages, activeStage }) => {
  const { formatMessage } = useIntl()

  return (
    <StickyMenu>
      {stages.map(({ data: stageData, ref }) => (
        <MenuItem
          isActive={stageData.nameDisplay === activeStage}
          key={stageData.nameDisplay}
          onClick={() => {
            scrollTo(ref.current)
          }}
        >
          {`0${stageData.position} - ${formatMessage({
            id: `pages.about.surveyOurProcess.${stageData.nameDisplay}`,
          })}`}
          <ChevronRight />
        </MenuItem>
      ))}
    </StickyMenu>
  )
}

export default DesktopMenu

DesktopMenu.propTypes = {
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      nameDisplay: PropTypes.string,
      position: PropTypes.number,
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          title: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  activeStage: PropTypes.string,
}

DesktopMenu.defaultProps = {
  activeStage: "",
}
