import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import H from "components/atoms/H"
import SectionSubtitle from "components/atoms/SectionSubtitle"
import SurveyOurProcess from "assets/animations/survey-our-process.json"
import lottie from "lottie-web"
import { useInView } from "react-intersection-observer"
import ProcessStages from "./ProcessStages"

import {
  SectionWrapper,
  HeroWrapper,
  HeadingWrapper,
  StyledImage,
  AnimationWrapper,
} from "./SurveyOurProcess.styles"

const SurveyOurProcessSection = ({ sectionData }) => {
  const [anim, setAnim] = useState(null)
  const [animIdle, setAnimIdle] = useState(false)
  const [ref, inView] = useInView({
    threshold: 0,
  })
  const animationContainer = useRef()

  useEffect(() => {
    const hero = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: SurveyOurProcess,
    })
    setAnim(hero)
    return () => hero.destroy()
  }, [])

  useEffect(() => {
    if (anim && anim.isPaused && inView && !animIdle) {
      anim.playSegments([0, 750], true)
      setAnimIdle(true)
    }
  }, [inView])

  useEffect(() => {
    let timer
    if (animIdle) {
      timer = setTimeout(() => {
        setAnimIdle(false)
      }, 45000)
    }
    return () => clearTimeout(timer)
  }, [animIdle])

  return (
    <SectionWrapper id="surveyOurProcess">
      <HeroWrapper ref={ref}>
        <HeadingWrapper>
          <SectionSubtitle>{sectionData.accentMainHeader}</SectionSubtitle>
          <H as="h2">{sectionData.descriptiveSubheader}</H>
        </HeadingWrapper>
        <AnimationWrapper>
          <StyledImage ref={animationContainer} />
        </AnimationWrapper>
      </HeroWrapper>
      <ProcessStages data={sectionData.processStage} />
    </SectionWrapper>
  )
}

export default SurveyOurProcessSection

SurveyOurProcessSection.propTypes = {
  sectionData: PropTypes.shape({
    accentMainHeader: PropTypes.string,
    descriptiveSubheader: PropTypes.string,
    processStage: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        nameDisplay: PropTypes.string,
        position: PropTypes.number,
        steps: PropTypes.arrayOf(
          PropTypes.shape({
            description: PropTypes.string,
            title: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
}
