import React, { useState } from "react"
import PropTypes from "prop-types"
import CaseStudyContent from "./CaseStudyContent"
import CaseStudyList from "./CaseStudyList"
import { ContentWrapper } from "./ImpactCaseStudy.styles"

const ImpactCaseStudy = ({ caseStudies }) => {
  const [activeCaseStudy, setActiveCaseStudy] = useState(caseStudies[0])

  const handleSelectCaseStudy = (caseStudyTitle) => {
    const selectedCaseStudy = caseStudies.find(
      (item) => item.tabTitle === caseStudyTitle
    )

    if (selectedCaseStudy) {
      setActiveCaseStudy(selectedCaseStudy)
    }
  }

  const handleSelectNext = () => {
    const currentIndex = caseStudies.indexOf(activeCaseStudy)

    if (currentIndex > -1 && currentIndex + 1 <= caseStudies.length - 1) {
      setActiveCaseStudy(caseStudies[currentIndex + 1])
    }
  }

  const handleSelectPrev = () => {
    const currentIndex = caseStudies.indexOf(activeCaseStudy)

    if (currentIndex > -1 && currentIndex - 1 >= 0) {
      setActiveCaseStudy(caseStudies[currentIndex - 1])
    }
  }

  return (
    <ContentWrapper>
      <CaseStudyList
        data={caseStudies.map((item) => item.tabTitle)}
        activeCaseStudy={activeCaseStudy.tabTitle}
        onSelect={(selected) => handleSelectCaseStudy(selected)}
        onNext={handleSelectNext}
        onPrev={handleSelectPrev}
      />
      <CaseStudyContent data={activeCaseStudy} />
    </ContentWrapper>
  )
}

export default ImpactCaseStudy

ImpactCaseStudy.propTypes = {
  caseStudies: PropTypes.arrayOf(
    PropTypes.shape({
      afterImage: PropTypes.string,
      beforeImage: PropTypes.string,
      building: PropTypes.string,
      description: PropTypes.string,
      land: PropTypes.string,
      listPosition: PropTypes.number,
      portfolioLink: PropTypes.string,
      projectTag: PropTypes.string,
      propertyTag: PropTypes.string,
      tabTitle: PropTypes.string,
    })
  ).isRequired,
}
