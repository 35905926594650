import styled, { css } from "styled-components"

const MobileMenuWrapper = styled.div`
  position: sticky;
  scroll-behavior: smooth;

  z-index: 2;
  top: 7.3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};

  padding: 0 1.6rem;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);

  display: flex;
  overflow-x: scroll;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    /* stylelint-disable */
    -webkit-appearance: none;
    /* stylelint-enable */
    display: none;
    width: 0;
    height: 0;
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  :-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::after {
    display: block;
    content: "";
    height: 100%;
    padding: 1rem;
    width: 1rem;
    flex-shrink: 0;
  }

  ${({ theme }) => theme.mq.medium} {
    display: none;
  }
`

const MenuItemButton = styled.button`
  border: none;
  box-shadow: none;
  background-color: transparent;
  white-space: nowrap;

  cursor: pointer;

  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ theme }) => theme.color.secondary02};

  margin-right: 2.8rem;
  padding: 2.2rem 1.4rem;

  &:last-of-type {
    margin-right: 0;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.color.secondary01};
      padding-bottom: 1.9rem;
      border-bottom: 3px solid ${({ theme }) => theme.color.main03};
    `}
`

export { MobileMenuWrapper, MenuItemButton }
