import styled, { css } from "styled-components"

const StyledHeroSection = styled.section`
  padding: 11rem 1.6rem 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${({ theme }) => theme.mq.small} {
    max-width: 96rem;
    margin: 0 auto;
    padding: 15rem 1.6rem 14rem;
  }
`

const PlayerWrapper = styled.div`
  max-width: 96rem;
  max-height: 54rem;
  margin-top: 1.6rem;

  ${({ theme }) => theme.mq.medium} {
    margin-top: 5.6rem;
  }
`

const paragraphStyles = css`
  font-size: ${({ theme }) => theme.font.size.l};
  line-height: 1.71;
  max-width: 70rem;
  margin-top: 1.6rem;

  ${({ theme }) => theme.mq.medium} {
    margin-top: 2.4rem;
  }
`

export { StyledHeroSection, PlayerWrapper, paragraphStyles }
