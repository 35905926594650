import React from "react"
import PropTypes from "prop-types"
import BuildingSize from "assets/images/building-size.svg"
import LandSize from "assets/images/land-size.svg"
import ProjectType from "assets/images/project-type.svg"
import PropertyType from "assets/images/property-type.svg"
import H from "components/atoms/H"
import P from "components/atoms/P"
import { useIntl } from "gatsby-plugin-intl"
import {
  headerStyles,
  paragraphStyles,
  ContentWrapper,
  IconWrapper,
  TextWrapper,
} from "./CaseStudySummary.styles"

const icons = {
  PROPERTY_TYPE: <PropertyType />,
  PROJECT_TYPE: <ProjectType />,
  LAND_SIZE: <LandSize />,
  BUILDING_SIZE: <BuildingSize />,
}

const CaseStudySummarySection = ({ data }) => {
  const { formatMessage } = useIntl()

  const renderContent = (type, content) => {
    if (["PROPERTY_TYPE", "PROJECT_TYPE"].includes(type)) {
      return formatMessage({
        id: `pages.about.seeOurImpact.${type}.${content}`,
      })
    }

    return content
  }

  return (
    <ContentWrapper>
      <IconWrapper>{icons[data.type]}</IconWrapper>
      <TextWrapper>
        <H as="h4" extraStyles={headerStyles}>
          {data.title}
        </H>
        <P extraStyles={paragraphStyles}>
          {renderContent(data.type, data.content)}
        </P>
      </TextWrapper>
    </ContentWrapper>
  )
}

export default CaseStudySummarySection

CaseStudySummarySection.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
}
