import styled from "styled-components"

const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.mq.small} {
    grid-template-columns: 1fr 1fr;
  }

  ${({ theme }) => theme.mq.large} {
    grid-template-columns: 1fr 1fr;
  }
`

export { SummaryWrapper }
