import styled from "styled-components"
import H from "../H"

const StyledHeading = styled(H)`
  font-size: ${({ theme }) => theme.font.size.s};
  font-family: ${({ theme }) => theme.font.family.body};
  font-weight: 600;
  color: ${({ theme }) => theme.color.secondary02};
  letter-spacing: 1px;
  line-height: 1.67;
  text-transform: uppercase;

  margin: 1.6rem 0;

  ${({ theme }) => theme.mq.small} {
    font-size: ${({ theme }) => theme.font.size.md};
  }

  ${({ extrastyles }) => extrastyles};
`

export { StyledHeading }
