import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { CarouselProvider, Slider } from "pure-react-carousel"
import BtnLink from "components/atoms/BtnLink"
import Button from "components/atoms/Button"
import ChevronLeft from "assets/icons/chevron-left.svg"
import ChevronRight from "assets/icons/chevron-right.svg"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import { useIntl } from "gatsby-plugin-intl"
import paths from "utils/paths"

import {
  StyledBackButton,
  StyledNextButton,
  StyledImage,
  GalleryButtons,
  PortfolioWrapper,
  ButtonsWrapper,
  StyledSlide,
  StyledSlideCount,
} from "./PortfolioGallery.styles"

const PortfolioGallery = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const { formatMessage } = useIntl()
  const carouselRef = useRef()
  const { portfolio } = paths

  const decreaseSlide = () => {
    const { carouselStore } = carouselRef.current
    const { state } = carouselStore

    const targetSlide = state.currentSlide - 1

    carouselStore.setStoreState({
      ...state,
      currentSlide: targetSlide,
    })
    setCurrentSlide(targetSlide)
    trackCustomEventTrigger({
      category: trackingCategoriesHelper.ABOUT,
      action: "Click",
      label: `${trackingCategoriesHelper.ABOUT} stage button(backwards)`,
    })
  }
  const increaseSlide = () => {
    const { carouselStore } = carouselRef.current
    const { state } = carouselStore

    const targetSlide = state.currentSlide + 1

    carouselStore.setStoreState({
      ...state,
      currentSlide: targetSlide,
    })

    setCurrentSlide(targetSlide)
    trackCustomEventTrigger({
      category: trackingCategoriesHelper.PROJECT_BRIEFING,
      action: "Click",
      label: `${trackingCategoriesHelper.PROJECT_BRIEFING} stage button(forward)`,
    })
  }

  const handleKeyDown = (event) => {
    const { carouselStore } = carouselRef.current
    const { state } = carouselStore
    const { key } = event

    if (
      (key === "ArrowRight" || key === "d") &&
      state.currentSlide < images.length - 1
    ) {
      increaseSlide()
    }
    if ((key === "ArrowLeft" || key === "a") && state.currentSlide >= 1) {
      decreaseSlide()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [currentSlide, images])

  return (
    <PortfolioWrapper>
      <CarouselProvider
        totalSlides={images.length}
        ref={carouselRef}
        touchEnabled={false}
        dragEnabled={false}
        currentSlide={currentSlide}
        naturalSlideWidth={100}
        naturalSlideHeight={62}
      >
        <Slider>
          {images.map((element, index) => (
            <StyledSlide index={index} key={element.image.title}>
              <StyledImage
                src={element.image.image}
                alt={element.image.title}
              />
            </StyledSlide>
          ))}
        </Slider>
        <ButtonsWrapper>
          <GalleryButtons>
            <StyledBackButton onClick={decreaseSlide}>
              <ChevronLeft />
            </StyledBackButton>
            <StyledSlideCount>{`${currentSlide + 1} — ${
              images.length
            }`}</StyledSlideCount>
            <StyledNextButton onClick={increaseSlide}>
              <ChevronRight />
            </StyledNextButton>
          </GalleryButtons>
          <BtnLink to={portfolio}>
            <Button>
              {formatMessage({ id: "pages.about.whatWeDo.portfolioButton" })}
            </Button>
          </BtnLink>
        </ButtonsWrapper>
      </CarouselProvider>
    </PortfolioWrapper>
  )
}

export default PortfolioGallery

PortfolioGallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number,
      image: PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
      }),
    })
  ).isRequired,
}
