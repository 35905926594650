import React from "react"
import PropTypes from "prop-types"
import SectionSubtitle from "components/atoms/SectionSubtitle"
import H from "components/atoms/H"
import {
  SectionWrapper,
  LocationsWrapper,
  HeadingWrapper,
} from "./LocationSection.styles"

import LocationItem from "./LocationItem"

const LocationSection = ({ sectionData }) => {
  return (
    <SectionWrapper>
      <HeadingWrapper>
        <SectionSubtitle>{sectionData.accentMainHeader}</SectionSubtitle>
        <H as="h2">{sectionData.descriptiveSubheader}</H>
      </HeadingWrapper>
      <LocationsWrapper>
        {sectionData.locationsSection.map((location) => (
          <LocationItem key={location.name} data={location} />
        ))}
      </LocationsWrapper>
    </SectionWrapper>
  )
}

export default LocationSection

LocationSection.propTypes = {
  sectionData: PropTypes.shape({
    accentMainHeader: PropTypes.string,
    descriptiveSubheader: PropTypes.string,
    locationsSection: PropTypes.arrayOf(
      PropTypes.shape({
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        addressLine3: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.shape({
          title: PropTypes.string,
          image: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
}
