import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

import { ListItem as ListItemWrapper } from "./CaseStudyList.styles"

const ListItem = ({ children, isActive, onClick, inView }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (inView && isActive && itemRef.current) {
      itemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      })
    }
  }, [isActive])

  return (
    <ListItemWrapper ref={itemRef} isActive={isActive} onClick={onClick}>
      {children}
    </ListItemWrapper>
  )
}

export default ListItem

ListItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  inView: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
}
