import React from "react"
import PropTypes from "prop-types"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import { graphql } from "gatsby"

import AboutHeroSection from "components/content/AboutSection/HeroSection"
import WhatWeDo from "components/content/AboutSection/WhatWeDo"
import SeeOurImpact from "components/content/AboutSection/SeeOurImpact"
import SurveyOurProcessSection from "components/content/AboutSection/SurveyOurProcess"
import LocationSection from "components/content/AboutSection/LocationSection"

const AboutPage = ({
  pageContext: { language },
  data: {
    api: { aboutPage },
  },
}) => {
  return (
    <Layout language={language}>
      <SEO title="About us" />
      <AboutHeroSection
        sectionData={{
          header: aboutPage.header,
          description: aboutPage.description,
          video: aboutPage.video,
        }}
      />
      <WhatWeDo sectionData={aboutPage.whatWeDoSection} />
      <SeeOurImpact sectionData={aboutPage.seeOurImpactSection} />
      <SurveyOurProcessSection
        sectionData={aboutPage.surveyOurProcessSection}
      />
      <LocationSection sectionData={aboutPage.aboutPageLocationsSection} />
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery($apiLocale: API_Locale) {
    api {
      aboutPage(language: $apiLocale) {
        description
        header
        video
        whatWeDoSection {
          accentMainHeader
          descriptiveSubheader
          portfolioImages {
            position
            image {
              image
              title
            }
          }
          projectType {
            type
          }
          propertyType {
            type
          }
          totalBuildingSize
          totalLandSize
          whatWeDoDescription
        }
        seeOurImpactSection {
          accentMainHeader
          descriptiveSubheader
          impactCaseStudy {
            afterImage
            beforeImage
            building
            description
            land
            listPosition
            portfolioLink
            projectTag
            propertyTag
            tabTitle
          }
        }
        surveyOurProcessSection {
          accentMainHeader
          descriptiveSubheader
          processStage {
            description
            nameDisplay
            position
            steps {
              description
              title
            }
          }
        }
        aboutPageLocationsSection {
          accentMainHeader
          descriptiveSubheader
          locationsSection {
            addressLine1
            addressLine2
            addressLine3
            name
            image {
              title
              image
            }
          }
        }
      }
    }
  }
`

export default AboutPage

AboutPage.propTypes = {
  data: PropTypes.shape({
    api: PropTypes.shape({
      aboutPage: PropTypes.shape({
        header: PropTypes.string,
        description: PropTypes.string,
        video: PropTypes.string,
        whatWeDoSection: PropTypes.shape({
          accentMainHeader: PropTypes.string,
          descriptiveSubheader: PropTypes.string,
          whatWeDoDescription: PropTypes.string,
          totalBuildingSize: PropTypes.string,
          totalLandSize: PropTypes.string,
          propertyType: PropTypes.arrayOf(
            PropTypes.shape({ type: PropTypes.string })
          ),
          projectType: PropTypes.arrayOf(
            PropTypes.shape({ type: PropTypes.string })
          ),
          portfolioImages: PropTypes.arrayOf(
            PropTypes.shape({
              position: PropTypes.number,
              image: PropTypes.shape({
                image: PropTypes.string,
                title: PropTypes.string,
              }),
            })
          ),
        }),
        seeOurImpactSection: PropTypes.shape({
          accentMainHeader: PropTypes.string,
          descriptiveSubheader: PropTypes.string,
          impactCaseStudy: PropTypes.arrayOf(
            PropTypes.shape({
              afterImage: PropTypes.string,
              beforeImage: PropTypes.string,
              building: PropTypes.string,
              description: PropTypes.string,
              land: PropTypes.string,
              listPosition: PropTypes.number,
              portfolioLink: PropTypes.string,
              projectTag: PropTypes.string,
              propertyTag: PropTypes.string,
              tabTitle: PropTypes.string,
            })
          ),
        }),
        surveyOurProcessSection: PropTypes.shape({
          accentMainHeader: PropTypes.string,
          descriptiveSubheader: PropTypes.string,
          processStage: PropTypes.arrayOf(
            PropTypes.shape({
              description: PropTypes.string,
              nameDisplay: PropTypes.string,
              position: PropTypes.number,
              steps: PropTypes.arrayOf(
                PropTypes.shape({
                  description: PropTypes.string,
                  title: PropTypes.string,
                })
              ),
            })
          ),
        }),
        aboutPageLocationsSection: PropTypes.shape({
          accentMainHeader: PropTypes.string,
          descriptiveSubheader: PropTypes.string,
          locationsSection: PropTypes.arrayOf(
            PropTypes.shape({
              addressLine1: PropTypes.string,
              addressLine2: PropTypes.string,
              addressLine3: PropTypes.string,
              name: PropTypes.string,
              image: PropTypes.shape({
                title: PropTypes.string,
                image: PropTypes.string,
              }),
            })
          ),
        }),
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
}
