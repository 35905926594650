import styled, { css } from "styled-components"

const SectionWrapper = styled.section`
  padding: 0rem 1.6rem 7rem;

  ${({ theme }) => theme.mq.large} {
    padding: 0rem 1.6rem 14rem;
    max-width: ${({ theme }) => theme.gridWidth};
    margin: 0 auto;
  }
`
const TextWrapper = styled.div`
  text-align: left;

  ${({ theme }) => theme.mq.large} {
    text-align: center;
  }
`
const ContentWrapper = styled.div`
  margin-top: 5.5rem;
  flex-direction: column;

  display: flex;

  ${({ theme }) => theme.mq.large} {
    flex-direction: row;
  }
`

const paragraphStyles = css`
  font-size: ${({ theme }) => theme.font.size.l};
  max-width: 98rem;
  margin: 2.4rem auto 0rem;
`

export { TextWrapper, SectionWrapper, ContentWrapper, paragraphStyles }
