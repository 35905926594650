import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import H from "components/atoms/H"
import P from "components/atoms/P"
import { useIntl } from "gatsby-plugin-intl"
import { useInView } from "react-intersection-observer"
import StageAnimation from "../StageAnimation"
import {
  headerStyles,
  descriptionStyles,
  stageHeader,
  stageDescription,
  HeadingWrapper,
  TextContent,
  StepsWrapper,
  StageWrapper,
} from "./Stage.styles"

const Stage = forwardRef(({ data }, ref) => {
  const { formatMessage } = useIntl()
  const [containerRef, inView] = useInView()

  return (
    <StageWrapper ref={ref}>
      <HeadingWrapper ref={containerRef}>
        <TextContent>
          <H as="h3" extraStyles={headerStyles}>
            {formatMessage({
              id: `pages.about.surveyOurProcess.${data.nameDisplay}`,
            })}
          </H>
          <P extraStyles={descriptionStyles}>{data.description}</P>
        </TextContent>
        <StageAnimation name={data.nameDisplay} isVisible={inView} />
      </HeadingWrapper>
      <StepsWrapper>
        {data.steps.map((step) => (
          <div key={step.title}>
            <H as="h4" extraStyles={stageHeader}>
              {step.title}
            </H>
            <P extraStyles={stageDescription}>{step.description}</P>
          </div>
        ))}
      </StepsWrapper>
    </StageWrapper>
  )
})

export default Stage

Stage.displayName = "Stage"

Stage.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    nameDisplay: PropTypes.string,
    position: PropTypes.number,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        title: PropTypes.string,
      })
    ),
  }).isRequired,
}
