import styled, { css } from "styled-components"
import { ButtonBack, ButtonNext, Slide } from "pure-react-carousel"
import Image from "components/atoms/Image"

const buttonCommonStyles = css`
  border: none;
  height: 5.6rem;
  width: 5.6rem;

  background-color: transparent;

  svg {
    width: 24px;
    height: 24px;
  }

  &:disabled {
    background-color: transparent;

    svg {
      fill: ${({ theme }) => theme.color.secondary03};
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.color.secondary05};

      :disabled {
        background-color: transparent;
      }
    }
  }
`

const StyledBackButton = styled(ButtonBack)`
  ${buttonCommonStyles}
`

const StyledNextButton = styled(ButtonNext)`
  ${buttonCommonStyles}
`

const StyledImage = styled(Image)`
  max-width: 50rem;
  max-height: 31rem;

  height: 100%;
  width: 100%;
`

const GalleryButtons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;

  ${({ theme }) => theme.mq.small} {
    margin-bottom: 0rem;
  }
`

const PortfolioWrapper = styled.div`
  width: 100%;
  max-width: 50rem;
  max-height: 40rem;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.6rem;

  ${({ theme }) => theme.mq.small} {
    flex-direction: row;
  }
`

const StyledSlideCount = styled.span`
  margin: 0 1.2rem;
  color: ${({ theme }) => theme.color.secondary01};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`

const StyledSlide = styled(Slide)`
  .carousel__slide-focus-ring {
    display: none !important;
  }
`

export {
  StyledBackButton,
  StyledNextButton,
  StyledImage,
  GalleryButtons,
  PortfolioWrapper,
  ButtonsWrapper,
  StyledSlide,
  StyledSlideCount,
}
