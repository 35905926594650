import React from "react"
import PropTypes from "prop-types"
import P from "components/atoms/P"
import H from "components/atoms/H"
import ReactPlayer from "react-player/lazy"
import getMediaUrl from "utils/getMediaUrl"
import {
  StyledHeroSection,
  PlayerWrapper,
  paragraphStyles,
} from "./AboutHeroSection.styles"

const HeroSection = ({ sectionData }) => {
  return (
    <StyledHeroSection>
      <H as="h2">{sectionData.header}</H>
      <P extraStyles={paragraphStyles}>{sectionData.description}</P>
      <PlayerWrapper>
        <ReactPlayer
          url={getMediaUrl(sectionData.video)}
          width="100%"
          height="100%"
          playing
          muted
          playsinline
          loop
        />
      </PlayerWrapper>
    </StyledHeroSection>
  )
}

export default HeroSection

HeroSection.propTypes = {
  sectionData: PropTypes.shape({
    header: PropTypes.string,
    description: PropTypes.string,
    video: PropTypes.string,
  }).isRequired,
}
