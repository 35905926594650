import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import {
  ImageWrapper,
  StyledImage,
  InfoText,
} from "./MobileCompareImage.styles"

const MobileCompareImage = ({ beforeImage, afterImage }) => {
  const [currentImage, setCurrentImage] = useState(beforeImage)
  const { formatMessage } = useIntl()
  const containerRef = useRef()

  useEffect(() => {
    setCurrentImage(beforeImage)
  }, [beforeImage, afterImage])

  useEffect(() => {
    const handleTouchStart = (e) => {
      e.preventDefault()
      e.stopImmediatePropagation()

      setCurrentImage(afterImage)
    }

    const handleTouchEnd = (e) => {
      e.preventDefault()
      e.stopImmediatePropagation()

      setCurrentImage(beforeImage)
    }

    const element = containerRef.current

    element.addEventListener("touchstart", handleTouchStart, { passive: false })
    element.addEventListener("touchend", handleTouchEnd, { passive: false })

    return () => {
      element.removeEventListener("touchstart", handleTouchStart)
      element.removeEventListener("touchend", handleTouchEnd)
    }
  }, [afterImage, beforeImage])

  return (
    <ImageWrapper
      ref={containerRef}
      onMouseDown={() => setCurrentImage(afterImage)}
      onMouseUp={() => setCurrentImage(beforeImage)}
    >
      <StyledImage src={currentImage} />
      {currentImage !== afterImage && (
        <InfoText>
          {formatMessage({ id: "pages.about.seeOurImpact.mobileImageInfo" })}
        </InfoText>
      )}
    </ImageWrapper>
  )
}

export default MobileCompareImage

MobileCompareImage.propTypes = {
  beforeImage: PropTypes.string.isRequired,
  afterImage: PropTypes.string.isRequired,
}
