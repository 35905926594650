import styled, { css } from "styled-components"
import Image from "components/atoms/Image"

const headerStyles = css`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};

  margin-bottom: 0.8rem;

  ${({ theme }) => theme.mq.medium} {
    max-width: 70%;
  }
`

const paragraphStyle = css`
  font-size: ${({ theme }) => theme.font.size.md};
  line-height: 1.75;
`

const StyledImage = styled(Image)`
  max-width: 36.8rem;
  height: 20.8rem;

  margin-bottom: 2.4rem;
`

const LocationItemWrapper = styled.div`
  margin-bottom: 3.2rem;

  ${({ theme }) => theme.mq.small} {
    margin-bottom: 0rem;
  }

  :last-of-type {
    margin-bottom: 0rem;
  }
`

export { headerStyles, paragraphStyle, StyledImage, LocationItemWrapper }
