import styled from "styled-components"

const SectionWrapper = styled.section`
  padding: 4rem 0rem 7rem;

  ${({ theme }) => theme.mq.large} {
    padding: 10rem 0em 14rem;
    max-width: ${({ theme }) => theme.gridWidth};
    margin: 0 auto;
  }
`

const HeroWrapper = styled.div`
  overflow: hidden;
  position: relative;
`
const HeadingWrapper = styled.div`
  text-align: left;
  margin: 0 auto;
  padding: 0 1.6rem;

  ${({ theme }) => theme.mq.medium} {
    text-align: center;
  }
`

const StyledImage = styled.div`
  position: absolute;
  top: -8rem;

  ${({ theme }) => theme.mq.medium} {
    top: -16rem;
  }
`

const AnimationWrapper = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  width: 54rem;
  height: 30rem;

  ${({ theme }) => theme.mq.medium} {
    width: 110rem;
    height: 58rem;
  }
`

export {
  SectionWrapper,
  HeroWrapper,
  HeadingWrapper,
  StyledImage,
  AnimationWrapper,
}
