import styled, { css } from "styled-components"
import { transparentize } from "polished"
import { Link } from "gatsby"

const paragraphStyles = css`
  font-size: ${({ theme }) => theme.font.size.l};
  color: ${({ theme }) => theme.color.secondary06};
  margin-top: 3.2rem;

  display: flex;
  flex-grow: 1;
  align-items: center;
  overflow: hidden;

  ${({ theme }) => theme.mq.medium} {
    margin-top: 0rem;
  }

  ${({ theme }) => theme.mq.large} {
    margin-top: 0rem;
    height: 17rem;
    max-width: 40rem;
  }
`

const ImageCaption = styled.span`
  display: inline;
  color: ${({ theme }) => theme.color.secondary03};
  text-align: center;
  padding-top: 1.6rem;
  font-size: ${({ theme }) => theme.font.size.s};

  ${({ theme }) => theme.mq.small} {
    text-align: left;
    width: 54rem;
  }

  ${({ theme }) => theme.mq.medium} {
    padding: 2rem 3.2rem;
  }

  ${({ theme }) => theme.mq.large} {
    padding: 2rem 0rem;
    text-align: center;
    width: 100%;
  }
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) =>
    transparentize(0.8, theme.color.secondary02)};

  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.large} {
    padding: 6.4rem 3.2rem 0 0;

    flex-direction: row;
  }
`

const ImageBlock = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.large} {
    min-width: 60%;
  }
`

const ImageWrapper = styled.div`
  height: 28rem;
  width: 100%;

  ${({ theme }) => theme.mq.small} {
    height: 32rem;
  }

  ${({ theme }) => theme.mq.medium} {
    height: 44rem;
  }

  ${({ theme }) => theme.mq.extraLarge} {
    width: 70rem;
  }
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.secondary03};
  font-size: ${({ theme }) => theme.font.size.s};
  text-decoration: underline;
  display: inline;
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 3rem 1.6rem;

  ${({ theme }) => theme.mq.medium} {
    padding: 0rem 0 5.6rem 3.2rem;

    flex-direction: column;
  }

  ${({ theme }) => theme.mq.large} {
    padding: 4.2rem 0 0 3.2rem;

    flex-direction: column;
  }

  ${({ theme }) => theme.mq.large} {
    max-width: 50%;
    max-height: 44rem;
  }
`

export {
  paragraphStyles,
  ImageCaption,
  ContentWrapper,
  ImageBlock,
  TextContent,
  ImageWrapper,
  StyledLink,
}
