// import SummarySection from "components/molecules/SummarySection"
import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import PropTypes from "prop-types"
import CaseStudySummarySection from "./SummarySection"
import { SummaryWrapper } from "./CaseStudySummary.styles"

const CaseStudySummary = ({ data }) => {
  const { formatMessage } = useIntl()

  const items = [
    {
      title: formatMessage({ id: "pages.about.caseStudy.propertyType" }),
      content: data.propertyTag,
      type: "PROPERTY_TYPE",
    },
    {
      title: formatMessage({ id: "pages.about.caseStudy.projectType" }),
      content: data.projectTag,
      type: "PROJECT_TYPE",
    },
    {
      title: formatMessage({ id: "pages.about.caseStudy.building" }),
      content: data.building,
      type: "BUILDING_SIZE",
    },
    {
      title: formatMessage({ id: "pages.about.caseStudy.land" }),
      content: data.land,
      type: "LAND_SIZE",
    },
  ]

  return (
    <SummaryWrapper>
      {items.map((item) => (
        <CaseStudySummarySection data={item} key={item.title} />
      ))}
    </SummaryWrapper>
  )
}

export default CaseStudySummary

CaseStudySummary.propTypes = {
  data: PropTypes.shape({
    propertyTag: PropTypes.string,
    projectTag: PropTypes.string,
    building: PropTypes.string,
    land: PropTypes.string,
  }).isRequired,
}
