import styled from "styled-components"
import Image from "components/atoms/Image"
import { transparentize } from "polished"

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  /* stylelint-disable */
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  /* stylelint-enable */

  position: relative;
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;

  /* stylelint-disable */
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  /* stylelint-enable */
`

const InfoText = styled.span`
  position: absolute;
  padding: 0.8rem 1.6rem;
  pointer-events: none;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);

  background-color: ${({ theme }) =>
    transparentize(0.2, theme.color.secondary01)};

  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  white-space: nowrap;
`

export { ImageWrapper, StyledImage, InfoText }
