import React, { useRef } from "react"
import PropTypes from "prop-types"
import getDimensions from "utils/getDimensions"
import { useIntl } from "gatsby-plugin-intl"
import MobileMenuItem from "./MobileMenuItem"
import { MobileMenuWrapper } from "./MobileMenu.styles"

const scrollTo = (element) => {
  const { offsetTop } = getDimensions(element)

  window.scrollTo({
    top: offsetTop - 120,
  })
}

const scrollMenu = (el, container) => {
  const left = el.offsetLeft
  container.scroll({ left: left - 16, behavior: "smooth" })
}

const MobileMenu = ({ stages, activeStage }) => {
  const containerRef = useRef(null)
  const { formatMessage } = useIntl()

  return (
    <MobileMenuWrapper ref={containerRef}>
      {stages.map(({ data: stageData, ref }) => (
        <MobileMenuItem
          isActive={stageData.nameDisplay === activeStage}
          key={stageData.nameDisplay}
          onScreenEntry={(el) => {
            scrollMenu(el, containerRef.current)
          }}
          onClick={(e) => {
            scrollMenu(e.target, containerRef.current)

            scrollTo(ref.current)
          }}
        >
          {`0${stageData.position} - ${formatMessage({
            id: `pages.about.surveyOurProcess.${stageData.nameDisplay}`,
          })}`}
        </MobileMenuItem>
      ))}
    </MobileMenuWrapper>
  )
}

export default MobileMenu

MobileMenu.propTypes = {
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      nameDisplay: PropTypes.string,
      position: PropTypes.number,
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          title: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  activeStage: PropTypes.string,
}

MobileMenu.defaultProps = {
  activeStage: "",
}
