import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import lottie from "lottie-web"

import Construction from "assets/animations/construction.json"
import Exit from "assets/animations/exit.json"
import ProjectPlanning from "assets/animations/project-planning.json"
import Promotion from "assets/animations/promotion.json"
import Validation from "assets/animations/validation.json"
import { AnimationContainer, StyledAnimation } from "./StageAnimation.styles"

const animations = {
  "Prospecting and Acquisition": Validation,
  "Project Development": ProjectPlanning,
  Construction,
  "Marketing and Operations": Promotion,
  Exit,
}

const StageAnimation = ({ isVisible, name }) => {
  const [anim, setAnim] = useState(null)
  const animationContainer = useRef()

  useEffect(() => {
    const hero = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: false,
      animationData: animations[name],
    })
    setAnim(hero)
    return () => hero.destroy()
  }, [])

  useEffect(() => {
    if (anim && anim.isPaused && isVisible) {
      anim.playSegments(0, true)
    }
  }, [isVisible])

  return (
    <AnimationContainer isVisible={isVisible}>
      <StyledAnimation ref={animationContainer} />
    </AnimationContainer>
  )
}

StageAnimation.propTypes = {
  isVisible: PropTypes.bool,
  name: PropTypes.string.isRequired,
}

StageAnimation.defaultProps = {
  isVisible: false,
}

export default StageAnimation
