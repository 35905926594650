import React from "react"
import PropTypes from "prop-types"
import ChevronLeft from "assets/icons/chevron-left.svg"
import ChevronRight from "assets/icons/chevron-right.svg"
import { useInView } from "react-intersection-observer"
import {
  ListWrapper,
  ListNavigation,
  ListContent,
  StyledButton,
  ButtonsDivider,
  StyledList,
  GradientBackground,
  GradientBackgroundLeft,
} from "./CaseStudyList.styles"

import ListItem from "./ListItem"

const CaseStudyList = ({ data, activeCaseStudy, onSelect, onNext, onPrev }) => {
  const [ref, inView] = useInView()

  return (
    <ListWrapper ref={ref}>
      <ListContent>
        <GradientBackgroundLeft />
        <StyledList>
          {data.map((item) => (
            <ListItem
              key={item}
              isActive={item === activeCaseStudy}
              inView={inView}
              onClick={() => onSelect(item)}
            >
              {item}
            </ListItem>
          ))}
        </StyledList>
        <GradientBackground />
      </ListContent>
      <ListNavigation>
        <StyledButton onClick={onPrev}>
          <ChevronLeft />
        </StyledButton>
        <ButtonsDivider />
        <StyledButton onClick={onNext}>
          <ChevronRight />
        </StyledButton>
      </ListNavigation>
    </ListWrapper>
  )
}

export default CaseStudyList

CaseStudyList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeCaseStudy: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
}
