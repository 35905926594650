import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { MenuItemButton } from "./MobileMenu.styles"

const MobileMenuItem = ({ isActive, onClick, children, onScreenEntry }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (isActive && itemRef.current) {
      onScreenEntry(itemRef.current)
    }
  }, [isActive])

  return (
    <MenuItemButton isActive={isActive} onClick={onClick} ref={itemRef}>
      {children}
    </MenuItemButton>
  )
}

export default MobileMenuItem

MobileMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onScreenEntry: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}
