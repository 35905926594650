import styled, { css } from "styled-components"

const StickyMenu = styled.div`
  align-self: flex-start;
  position: sticky;
  top: 12rem;
  margin: 6rem 0 0 1.6rem;

  width: 30%;
  flex-shrink: 0;

  display: none;

  ${({ theme }) => theme.mq.medium} {
    display: block;
  }
`

const MenuItem = styled.button`
  border: none;
  box-shadow: none;
  width: 100%;
  text-align: left;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.color.secondary05};
  cursor: pointer;
  transition: background-color 0.4s;

  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary02};

  border-bottom: 1px solid ${({ theme }) => theme.color.secondary04};

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.color.secondary04};
    }
  }

  &:last-of-type {
    border-bottom: none;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.color.secondary01};
      box-shadow: inset 4px 0px 0px 0px ${({ theme }) => theme.color.main03};
    `}

  svg {
    fill: ${({ theme }) => theme.color.secondary02};
  }
`

export { StickyMenu, MenuItem }
