import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import useWindowSize from "utils/useWindowSize"
import theme from "styles/theme"
import getDimensions from "utils/getDimensions"
import Stage from "../Stage"
import MobileMenu from "./MobileMenu"
import DesktopMenu from "./DesktopMenu"
import { ProcessStagesWrapper, StagesWrapper } from "./ProcessStages.styles"

const getMappedStages = (stages, refs) => {
  return stages.map((stage, index) => ({
    data: stage,
    ref: refs[index],
  }))
}

const ProcessStages = ({ data }) => {
  const [activeSection, setActiveSection] = useState(data[0])
  const size = useWindowSize()

  const prospectingRef = useRef(null)
  const projectRef = useRef(null)
  const constructionRef = useRef(null)
  const marketingRef = useRef(null)
  const exitRef = useRef(null)

  const stages = getMappedStages(data, [
    prospectingRef,
    projectRef,
    constructionRef,
    marketingRef,
    exitRef,
  ])

  useEffect(() => {
    const handleScroll = () => {
      const isMobile = size.width < theme.breakpoints.medium

      const scrollPosition = isMobile
        ? window.scrollY + 200
        : window.scrollY + 133

      const selected = stages.find(({ ref }) => {
        const ele = ref.current

        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele)

          return (
            scrollPosition > offsetTop && scrollPosition - 73 < offsetBottom
          )
        }

        return false
      })

      if (
        selected &&
        selected.data.nameDisplay !== activeSection?.nameDisplay
      ) {
        setActiveSection(selected.data)
      } else if (!selected && activeSection) {
        setActiveSection(data[0])
      }
    }

    handleScroll()
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [activeSection])

  return (
    <ProcessStagesWrapper>
      <DesktopMenu stages={stages} activeStage={activeSection?.nameDisplay} />
      <MobileMenu stages={stages} activeStage={activeSection?.nameDisplay} />
      <StagesWrapper>
        {stages.map((stage) => (
          <Stage
            data={stage.data}
            key={stage.data.nameDisplay}
            ref={stage.ref}
          />
        ))}
      </StagesWrapper>
    </ProcessStagesWrapper>
  )
}

export default ProcessStages

ProcessStages.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      nameDisplay: PropTypes.string,
      position: PropTypes.number,
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          title: PropTypes.string,
        })
      ),
    })
  ).isRequired,
}
