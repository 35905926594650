import styled from "styled-components"

const SectionWrapper = styled.section`
  padding: 8rem 1.6rem 8rem;

  ${({ theme }) => theme.mq.large} {
    padding: 16rem 1.6rem 12rem;
    max-width: ${({ theme }) => theme.gridWidth};
    margin: 0 auto;
  }
`
const LocationsWrapper = styled.div`
  margin-top: 2.4rem;

  display: grid;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.mq.small} {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2.4rem;
    column-gap: 3.2rem;
    margin-top: 4rem;
  }

  ${({ theme }) => theme.mq.large} {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 4.8rem;
  }
`

const HeadingWrapper = styled.div`
  ${({ theme }) => theme.mq.medium} {
    max-width: 70%;
  }
`

export { SectionWrapper, LocationsWrapper, HeadingWrapper }
