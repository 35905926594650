import React from "react"
import PropTypes from "prop-types"
import P from "components/atoms/P"
import H from "components/atoms/H"
import SectionSubtitle from "components/atoms/SectionSubtitle"

import {
  SectionWrapper,
  TextWrapper,
  paragraphStyles,
  ContentWrapper,
} from "./WhatWeDo.styles"
import WhatWeDoSummary from "./WhatWeDoSummary"
import PortfolioGallery from "./PortfolioGallery"

const WhatWeDo = ({ sectionData }) => {
  const {
    accentMainHeader,
    descriptiveSubheader,
    whatWeDoDescription,
    portfolioImages,
    ...summary
  } = sectionData

  return (
    <SectionWrapper id="whatWeDo">
      <TextWrapper>
        <SectionSubtitle>{accentMainHeader}</SectionSubtitle>
        <H as="h2">{descriptiveSubheader}</H>
        <P extraStyles={paragraphStyles}>{whatWeDoDescription}</P>
      </TextWrapper>
      <ContentWrapper>
        <WhatWeDoSummary summaryData={summary} />
        <PortfolioGallery images={portfolioImages} />
      </ContentWrapper>
    </SectionWrapper>
  )
}

export default WhatWeDo

WhatWeDo.propTypes = {
  sectionData: PropTypes.shape({
    accentMainHeader: PropTypes.string,
    descriptiveSubheader: PropTypes.string,
    whatWeDoDescription: PropTypes.string,
    portfolioImages: PropTypes.arrayOf(
      PropTypes.shape({
        position: PropTypes.number,
        image: PropTypes.shape({
          image: PropTypes.string,
          title: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
}
